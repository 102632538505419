






























import Vue from "vue";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import Component from "vue-class-component";
import utils from "@/utilities/Utils";
import { ILookupItem, LookupItem } from "@/model/LookupItem";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class LookupItemDialogue extends Vue {
    
    //
    // -- properties
    //

    @Ref("lookupItemForm") private readonly lookupItemForm!: VForm;

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private item: LookupItem = new LookupItem();

    //
    // -- methods
    //

    add(id: number): void {
        this.reset();
        this.item.groupID = id;
        this.dialogueTitle = "Add Item";
        this.showDialogue = true;
    }

    async edit(id: number): Promise<void> {
        this.reset();
        const lookupData: ILookupItem = await apiClient.get(`api/admin/LoadLookup?id=${id}`);
        this.item.update(lookupData);
        this.dialogueTitle = "Edit Item";
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.item);
        this.lookupItemForm?.resetValidation();
    }

    private async save() {
        if (!this.lookupItemForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        await apiClient.post("/api/admin/saveLookup", this.item, "lookup-save");

        toastr.success("Saved");
        this.$emit("saved");    
        this.showDialogue = false;
    }

}
