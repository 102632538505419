import { mapData } from "@/utilities/DataMapping";

export interface ILookupGroup {
    id: number;
    description: string;
    isArchived: boolean;
}

export class LookupGroup implements ILookupGroup {

    constructor(data?: ILookupGroup) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ILookupGroup): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    description: string = "";
    isArchived: boolean = false;
}