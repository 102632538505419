
































import Vue from "vue";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import Component from "vue-class-component";
import LookupItemDialogue from "@/vue/components/dialogues/LookupItemDialogue.vue";
import utils from "@/utilities/Utils";
import { ILookupGroup, LookupGroup } from "@/model/LookupGroup";
import { ILookupItem, LookupItem } from "@/model/LookupItem";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton, 
        LookupItemDialogue 
    }
})
export default class Lookups extends Vue {

    mounted(): void {
        this.loadGroups();
    }

    //
    // -- properties
    //

    private lookupItemHeaders = [
        { text: "Description", value: "description" },
        { text: "Is Archived?", value: "isArchived" },
        { text: "Created", value: "created" },
        { text: "Last Updated", value: "lastUpdated" }
    ]

    private groupList: Array<ILookupGroup> = [];
    private itemList: Array<ILookupItem> = [];
    private readonly lookupItem = new LookupItem();
    groupID: number = 0;

    //
    // -- computed properties
    //

    private get totalCount(): number {
        return this.itemList.length;
    }

    private get dialogueTitle(): string {
        if (!this.lookupItem) return "- - -";
        if (utils.isEmptyId(this.lookupItem.id)) return "New Item";
        return "Edit " + this.lookupItem.description;
    }

    private get countText(): string {
        return this.totalCount === -1 ? "..." : this.totalCount.toString();
    }

    //
    // -- watchers
    //

    @Watch("groupID")
    private onGroupIDChanged() {
        if (this.groupID > 0) this.search();
    }

    //
    // -- methods
    //

    private async loadGroups() {
        this.groupList = [];
        const response = await apiClient.get("/api/admin/loadGroups");
        this.groupList.push(...response.map((c: ILookupGroup) => new LookupGroup(c)));
    }

    private addNew() {
        const dialogue: LookupItemDialogue = this.$refs.lookupItemDialogue as LookupItemDialogue;
        dialogue.add(this.groupID);
    }

    private edit(item: ILookupItem) {
        const dialogue: LookupItemDialogue = this.$refs.lookupItemDialogue as LookupItemDialogue;
        dialogue.edit(item.id);
    }

    private async search() {
        if (this.groupID < 1) {
            toastr.error("Missing group ID!");
            return;
        }

        this.itemList = [];
        const response = await apiClient.get(`/api/admin/loadLookups?id=${this.groupID}&includeArchived=${true}`);
        this.itemList.push(...response.map((l: ILookupItem) => new LookupItem(l)));
    }
}
